import {
    put,
    select,
    takeLatest,
    delay,
    take,
    fork,
    cancel,
    cancelled,
} from 'redux-saga/effects';

import ActionsTypes from '../../constants/ActionsTypes';

import {
    logsActionCreators,
    modalsActionCreators,
    errorManagerActionCreators,
    globalLoadingActionCreators
} from '../actions';

import API from '../../constants/ApiUrl'
import { selectIdsUser } from '../selectors/user'

import * as R from '../../constants/ApiConstants'

const getLogsAlert = function* () {
    // starts the task in the background
    const bgSyncTask = yield fork(getLogsAlertLongPolling);

    // wait for the user stop action
    yield take(ActionsTypes.CLOSE_LOGS);

    // user clicked stop. cancel the background task
    // this will cause the forked bgSync task to jump into its finally block
    yield cancel(bgSyncTask)
};

const getLogsAlertLongPolling = function* () {

    const user = yield select(selectIdsUser);
    let count = 0;

    try {
        while (true) {
            let data;
            if (count) {
                data = yield delay(300000, API(user.jwt).get(R.GET_LOGS_ALERT()));
            } else {
                data = yield API(user.jwt).get(R.GET_LOGS_ALERT());
            }
            yield put(logsActionCreators.getLogsAlertSuccess(data.data));
            count++
        }
    } catch (e) {
        yield put(logsActionCreators.getLogsAlertFailure(e.response));
    } finally {
        if (yield cancelled())
            yield put(logsActionCreators.getLogsAlertFailure('STOP'))
    }
};

const putRemotedLogAlert = function* ({ payload }) {

    const idLogAlert = payload.idLogAlert;

    const user = yield select(selectIdsUser);


    try {
        const data = yield API(user.jwt).put(R.REMOTED_LOG_ALERT(idLogAlert));

        yield put(logsActionCreators.closeLogsPolling());
        yield put(logsActionCreators.getLogsAlert(data.data));
        yield put(logsActionCreators.putRemotedLogAlertSuccess(data.data));

        const modal = {
            key: 'modal__logDetail__modalUpdateIssueRaisedSuccess_body',
            title: 'modal__logDetail__modalUpdateIssueRaisedSuccess_title',
            level: 'validation',
            show: true,
            path: '',
        };
        yield put(modalsActionCreators.hydrateModal(modal));

    } catch (e) {
        if (e.response.status === 400) {
            const modal = {
                key: e.response.data.traductionKey,
                title: 'modal__logDetail__modalUpdateFailed_title',
                level: 'error',
                show: true,
                path: '',
            };

            yield put(modalsActionCreators.hydrateModal(modal));
        } else {
            yield put(errorManagerActionCreators.createError(e.response));
        }

        yield put(logsActionCreators.putResolvedLogAlertFailure(e.response));
    }
};

const putResolvedLogAlert = function* ({ payload }) {

    const idLogAlert = payload.idLogAlert;

    const user = yield select(selectIdsUser);

    try {
        const data = yield API(user.jwt).put(R.RESOLVED_LOG_ALERT(idLogAlert));
        yield put(logsActionCreators.closeLogsPolling());
        yield put(logsActionCreators.getLogsAlert());
        yield put(logsActionCreators.putResolvedLogAlertSuccess(data.data));

        const modal = {
            key: 'modal__logDetail__modalUpdateIssueSolvedSuccess_body',
            title: 'modal__logDetail__modalUpdateIssueSolvedSuccess_title',
            level: 'validation',
            show: true,
            path: '',
        };
        yield put(modalsActionCreators.hydrateModal(modal));

    } catch (e) {
        if (e.response.status === 400) {
            const modal = {
                key: e.response.data.traductionKey,
                title: 'modal__logDetail__modalUpdateFailed_title',
                level: 'error',
                show: true,
                path: '',
            };

            yield put(modalsActionCreators.hydrateModal(modal));
        } else {
            yield put(errorManagerActionCreators.createError(e.response));
        }

        yield put(logsActionCreators.putResolvedLogAlertFailure(e.response));
    }
};

const putResolvedAllLogAlert = function* ({ payload }) {

    const user = yield select(selectIdsUser);
    let apiCall;
    apiCall = R.RESOLVED_ALL_LOG_ALERT()
    try {
        const data = yield API(user.jwt).put(apiCall, payload.ids);
        yield put(logsActionCreators.closeLogsPolling());
        yield put(logsActionCreators.getLogsAlert());
        yield put(logsActionCreators.putResolvedAllLogAlertSuccess(data.data));

        const modal = {
            key: 'modal__logDetail__modalUpdateIssueSolvedSuccess_body',
            title: 'toast__notif_closed',
            level: 'validation',
            show: true,
            path: '',
        };
        yield put(modalsActionCreators.hydrateModal(modal));

    } catch (e) {
        if (e.response.status === 400) {
            const modal = {
                key: e.response.data.traductionKey,
                title: 'modal__logDetail__modalUpdateFailed_title',
                level: 'error',
                show: true,
                path: '',
            };

            yield put(modalsActionCreators.hydrateModal(modal));
        } else {
            yield put(errorManagerActionCreators.createError(e.response));
        }

        yield put(logsActionCreators.putResolvedAllLogAlertFailure(e.response));
    }
};





const getLogsHistory = function* ({ payload }) {

    const user = yield select(selectIdsUser);
    user['idUser'] = payload.idUser;
    const dateSelected = payload.date;
    const scope = payload.scope;
    let apiCall;

    if (scope === 'kiosks') {
        apiCall = R.GET_LOGS_HISTORY(user)
    } else if (scope === 'user' && user.idUser) {
        apiCall = R.GET_LOGS_HISTORY_USER(user)
    } else if (scope === 'users') {
        apiCall = R.GET_LOGS_HISTORY_USERS(user)
    }

    yield put(globalLoadingActionCreators.startLoading());

    try {
        const data = yield API(user.jwt).post(apiCall, dateSelected);

        yield put(logsActionCreators.getLogsHistorySuccess(data.data))

    } catch (e) {

        yield put(errorManagerActionCreators.createError(e.response));

        yield put(logsActionCreators.getLogsHistoryFailure(e.response));
    }
    yield put(globalLoadingActionCreators.endLoading());
};


const sagas = function* () {
    yield takeLatest(ActionsTypes.GET_LOGS_ALERT, getLogsAlert);
    yield takeLatest(ActionsTypes.PUT_REMOTED_LOG_ALERT, putRemotedLogAlert);
    yield takeLatest(ActionsTypes.PUT_RESOLVED_LOG_ALERT, putResolvedLogAlert);
    yield takeLatest(ActionsTypes.GET_LOGS_HISTORY, getLogsHistory);
    yield takeLatest(ActionsTypes.PUT_RESOLVED_ALL_LOG_ALERT, putResolvedAllLogAlert);

};

export default sagas